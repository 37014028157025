<template>
  <div>
    <v-app :style="colorVapp">
      <onebox_toolbar></onebox_toolbar>
      <v-main>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular
            indeterminate
            size="64"
            :color="color.theme"
          ></v-progress-circular>
          <br />
          <br />
          <span :style="colorProgress">&nbsp; &nbsp; loading</span>
        </v-overlay>
        <!-- mobile title -->
        <v-list
          nav
          :color="color.BG"
          class="px-4"
          v-if="resolutionScreen < 500"
        >
          <v-card
            rounded="xl"
            class="elevation-0 d-flex align-center"
            height="40"
          >
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center">
                <v-icon dark size="24" :color="color.theme"
                  >mdi-account-tie</v-icon
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span :style="headerPage">{{ $t("toolbar.dashboardoutbox.name") }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-list>
        <!-- desktop title -->
        <v-list nav :color="color.BG" v-else>
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-account-tie</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="ml-n2 pt-2 pb-1">
                <v-btn rounded text disabled>
                  <span :style="headerPage">{{ $t("toolbar.dashboardoutbox.name") }}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card
          class="elevation-0 d-flex"
          :color="color.BG"
          id="thiscontainer_dashboardoutbox"
        >
          <v-container class="pa-lg-0 pa-3">
            <!-- mobile จำนวน / ผลรวม -->
            <v-layout justify-center wrap row mb-sm-2 mb-0 v-if="resolutionScreen < 500">
              <!-- จำนวน / ผลรวม (ทั้งหมด) -->
              <v-flex pa-2 lg5 sm6 xs12>
                <v-card hover :style="cardDashborad" class="d-flex align-center">
                  <v-card-text class="pa-2">
                    <v-list>
                      <v-list-item class="px-md-2 px-0">
                        <v-badge class="mr-3" color="transparant" :style="iconDashboard">
                          <v-avatar color="#A8D1E7" size="40">
                            <v-icon color="white">mdi-file-document</v-icon>
                          </v-avatar>
                        </v-badge>
                        <v-list-item-title class="text-wrap">
                          <div v-if="loaddataprogress === true" class="mt-n3">
                            <v-skeleton-loader
                              class="mx-auto"
                              height="55px"
                              type="list-item-two-line"
                            ></v-skeleton-loader>
                          </div>
                          <div v-else>
                            <span class="black--text font-weight-regular">
                              {{ $t("toolbar.dashboardoutbox.totaloutbox") }}
                            </span>
                            <h2 class="black--text font-weight-regular my-1">{{ total_outbox }} {{ ($t("toolbar.file")) }}</h2>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex pa-2 lg5 sm6 xs12>
                <v-card hover :style="cardDashborad" class="d-flex align-center">
                  <v-card-text class="pa-2">
                    <v-list>
                      <v-list-item class="px-md-2 px-0">
                        <v-badge overlap class="mr-3" color="transparant" :style="iconDashboard_lastMonths">
                          <span slot="badge"><v-icon color="#F898A4" size="30" class="ml-n3">mdi-plus-thick</v-icon></span>
                          <v-avatar color="#80B7A2" size="40">
                            <v-icon color="white">mdi-file-document</v-icon>
                          </v-avatar>
                        </v-badge>
                        <v-list-item-title class="text-wrap">
                          <div v-if="loaddataprogress === true" class="mt-n3">
                            <v-skeleton-loader
                              class="mx-auto"
                              height="55px"
                              type="list-item-two-line"
                            ></v-skeleton-loader>
                          </div>
                          <div v-else>
                            <span class="black--text font-weight-regular">
                              {{ $t("toolbar.dashboardoutbox.totalfilesize") }}
                            </span>
                            <h2 class="black--text font-weight-regular my-1">{{ convertFileSize(total_filesize) }}</h2>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <!-- จำนวน / ผลรวม (ย้อนหลัง 6 เดือน) -->
              <v-flex pa-2 lg5 sm6 xs12>
                <v-card hover :style="cardDashborad" class="d-flex align-center">
                  <v-card-text class="pa-2">
                    <v-list>
                      <v-list-item class="px-md-2 px-0">
                        <v-badge overlap bottom class="mr-3" color="#FCEE9E" :style="iconDashboard">
                          <h2 slot="badge" :style="badgeText"> 6 </h2>
                          <v-avatar color="#A8D1E7" size="40">
                            <v-icon color="white">mdi-calendar-month</v-icon>
                          </v-avatar>
                        </v-badge>
                        <v-list-item-title class="text-wrap">
                          <div v-if="loaddataprogress === true" class="mt-n3">
                            <v-skeleton-loader
                              class="mx-auto"
                              height="55px"
                              type="list-item-two-line"
                            ></v-skeleton-loader>
                          </div>
                          <div v-else>
                            <span class="black--text font-weight-regular">
                              {{ $t("toolbar.dashboardoutbox.outbox_last6months") }}
                            </span>
                            <h2 class="black--text font-weight-regular my-1">{{ total_outbox_last6months }} {{ ($t("toolbar.file")) }}</h2>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex pa-2 lg5 sm6 xs12>
                <v-card hover :style="cardDashborad" class="d-flex align-center">
                  <v-card-text class="pa-2">
                    <v-list>
                      <v-list-item class="px-md-2 px-0">
                        <v-badge overlap class="mr-3" color="transparant" :style="iconDashboard_lastMonths">
                          <span slot="badge"><v-icon color="#F898A4" size="30" class="ml-n3">mdi-plus-thick</v-icon></span>
                          <v-badge overlap bottom color="#FCEE9E">
                            <h2 slot="badge" :style="badgeText"> 6 </h2>
                            <v-avatar color="#80B7A2" size="40">
                              <v-icon color="white">mdi-calendar-month</v-icon>
                            </v-avatar>
                          </v-badge>
                        </v-badge>
                        <v-list-item-title class="text-wrap">
                          <div v-if="loaddataprogress === true" class="mt-n3">
                            <v-skeleton-loader
                              class="mx-auto"
                              height="55px"
                              type="list-item-two-line"
                            ></v-skeleton-loader>
                          </div>
                          <div v-else>
                            <span class="black--text font-weight-regular">
                              {{ $t("toolbar.dashboardoutbox.filesize_last6months") }}
                            </span>
                            <h2 class="black--text font-weight-regular my-1">{{ convertFileSize(total_filesize_last6months) }}</h2>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
            <!-- desktop จำนวน / ผลรวม -->
            <v-layout justify-center wrap row mb-3 v-else>
              <!-- จำนวน / ผลรวม (ทั้งหมด) -->
              <v-flex py-lg-0 pa-2 lg3 sm6 xs12>
                <v-card hover :style="cardDashborad">
                  <v-card-text class="pa-2 text-center">
                    <v-badge color="transparant" :style="iconDashboard">
                      <v-avatar color="#A8D1E7" size="40">
                        <v-icon color="white">mdi-file-document</v-icon>
                      </v-avatar>
                    </v-badge>
                    <div v-if="loaddataprogress === true">
                      <v-skeleton-loader
                        class="mx-auto"
                        height="55px"
                        type="list-item-two-line"
                      ></v-skeleton-loader>
                    </div>
                    <div v-else>
                      <h1 class="black--text font-weight-regular my-2">{{ total_outbox }} {{ ($t("toolbar.file")) }}</h1>
                      <span class="black--text font-weight-regular">
                        {{ $t("toolbar.dashboardoutbox.totaloutbox") }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex py-lg-0 pa-2 lg3 sm6 xs12>
                <v-card hover :style="cardDashborad">
                  <v-card-text class="pa-2 text-center">
                    <v-badge overlap color="transparant" :style="iconDashboard_lastMonths">
                      <span slot="badge"><v-icon color="#F898A4" size="30" class="ml-n3">mdi-plus-thick</v-icon></span>          
                      <v-avatar color="#80B7A2" size="40">
                        <v-icon color="white">mdi-file-document</v-icon>
                      </v-avatar>                        
                    </v-badge>
                    <div v-if="loaddataprogress === true">
                      <v-skeleton-loader
                        class="mx-auto"
                        height="55px"
                        type="list-item-two-line"
                      ></v-skeleton-loader>
                    </div>
                    <div v-else>
                      <h1 class="black--text font-weight-regular my-2">{{ convertFileSize(total_filesize) }}</h1>
                      <span class="black--text font-weight-regular">
                        {{ $t("toolbar.dashboardoutbox.totalfilesize") }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
              <!-- จำนวน / ผลรวม (ย้อนหลัง 6 เดือน) -->
              <v-flex py-lg-0 pa-2 lg3 sm6 xs12>
                <v-card hover :style="cardDashborad">
                  <v-card-text class="pa-2 text-center">
                    <v-badge overlap bottom color="#FCEE9E" :style="iconDashboard">
                      <h2 slot="badge" :style="badgeText"> 6 </h2>
                      <v-avatar color="#A8D1E7" size="40">
                        <v-icon color="white">mdi-calendar-month</v-icon>
                      </v-avatar>
                    </v-badge>
                    <div v-if="loaddataprogress === true" class="mt-2">
                      <v-skeleton-loader
                        class="mx-auto"
                        height="55px"
                        type="list-item-two-line"
                      ></v-skeleton-loader>
                    </div>
                    <div v-else>
                      <h1 class="black--text font-weight-regular my-2">{{ total_outbox_last6months }} {{ ($t("toolbar.file")) }}</h1>
                      <span class="black--text font-weight-regular" style="text-wrap: nowrap;">
                        {{ $t("toolbar.dashboardoutbox.outbox_last6months") }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex py-lg-0 pa-2 lg3 sm6 xs12>
                <v-card hover :style="cardDashborad">
                  <v-card-text class="pa-2 text-center">
                    <v-badge overlap color="transparant" :style="iconDashboard_lastMonths">
                      <span slot="badge"><v-icon color="#F898A4" size="30" class="ml-n3">mdi-plus-thick</v-icon></span>
                      <v-badge overlap bottom color="#FCEE9E">
                        <h2 slot="badge" :style="badgeText"> 6 </h2>
                        <v-avatar color="#80B7A2" size="40">
                          <v-icon color="white">mdi-calendar-month</v-icon>
                        </v-avatar>
                      </v-badge>
                    </v-badge>
                    <div v-if="loaddataprogress === true" class="mt-2">
                      <v-skeleton-loader
                        class="mx-auto"
                        height="55px"
                        type="list-item-two-line"
                      ></v-skeleton-loader>
                    </div>
                    <div v-else>
                      <h1 class="black--text font-weight-regular my-2">{{ convertFileSize(total_filesize_last6months) }}</h1>
                      <span class="black--text font-weight-regular" style="text-wrap: nowrap;">
                        {{ $t("toolbar.dashboardoutbox.filesize_last6months") }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>

            <!-- กราฟ / ตาราง -->
            <v-layout justify-center wrap row>
              <v-flex py-sm-0 pa-2 sm6>
                <v-card class="pa-3" hover :style="cardDashborad">
                  <div v-if="loaddataprogress === true">
                    <v-skeleton-loader
                      class="mx-auto"
                      height="375px"
                      type="image, list-item-two-line"
                    ></v-skeleton-loader>
                  </div>
                  <div id="chart" v-else>
                    <apexchart :height="resolutionScreen < 500 ? 435 : 360" :options="chartOptions" :series="series"></apexchart>
                  </div>
                  <h4 class="pt-2 text-center">{{ $t("toolbar.dashboardoutbox.outboxpermonth") }}</h4>
                </v-card>
              </v-flex>
              <!-- mobile ตาราง -->
              <v-flex py-sm-0 pa-2 sm6 v-if="resolutionScreen < 500">
                <v-card class="py-3 px-5" hover :style="cardDashborad">
                  <div v-if="loaddataprogress_table === true">
                    <v-skeleton-loader
                      class="mx-auto"
                      height="375px"
                      type="table"
                    ></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <v-text-field
                      v-model="search"
                      class="mb-2"
                      append-icon="mdi-magnify"
                      :label="$t('toolbar.searchCompanyTaxid')"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-card :style="displayMobile">
                      <v-list
                        two-line
                        class="elevation-0 px-2"
                        v-if="totaldatabusiness !== 0"
                        :style="styleDisplayMobile"
                      >
                        <v-list-item
                          v-for="item in databusiness"
                          :key="item.title"
                          :style="displayMobileForListItem"
                          @click="gotoDialogDashboard(item.receiver_taxid, item)"
                          style="cursor: pointer;"
                        >
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 14px;">
                              <v-icon large :color="color.theme" style="font-weight: 100">business</v-icon>
                              {{ item.receiver_name}}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-card-text class="mt-4 mb-4" v-else>
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                          class="text-center mt-8"
                        >
                          {{$t('tablefile.empty')}} 
                        </v-list-item-title>
                      </v-card-text>
                    </v-card>
                    <v-pagination
                      v-if="totaldatabusiness > 0"
                      class="pt-3"
                      v-model="page"
                      :length="pageCount || 0"
                      :color="color.theme"
                      :total-visible="5"
                    ></v-pagination>
                  </div>
                  <h4 class="pt-3 text-center">{{ $t("toolbar.dashboardoutbox.listcompany") }}</h4>
                </v-card>
              </v-flex>
              <!-- desktop ตาราง -->
              <v-flex v-else py-sm-0 pa-2 sm6>
                <v-card class="py-3 px-5" hover :style="cardDashborad">
                  <div v-if="loaddataprogress_table === true">
                    <v-skeleton-loader
                      class="mx-auto"
                      height="375px"
                      type="table"
                    ></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <v-text-field
                      class="pa-0"
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('toolbar.searchCompanyTaxid')"
                      single-line
                      hide-details
                      clearable
                      @click:clear="clearData"
                      @keyup="searchDataBusiness"
                    ></v-text-field>
                    <v-data-table
                      :headers="headers"
                      :items="databusiness"
                      item-key="name"
                      :no-data-text="$t('myoutboxPage.Nodata')"
                      :show-select="false"
                      class="elevation-1"
                      item-selected
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                      :page.sync="page"
                      :items-per-page="size"
                    >
                      <template v-slot:[`header.system`]="{ header }">
                        <span :style="headerTable" >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:item="props">
                        <tr
                          @click="gotoDialogDashboard(props.item.receiver_taxid, props.item)"
                          style="cursor: pointer;"
                        >
                          <td width="7%" class="text-center">
                            <v-icon :color="color.theme" style="font-weight: 100">business</v-icon>
                          </td>
                          <td width="63%">{{ props.item.receiver_name}}</td>
                          <td width="30%" class="text-center">{{ props.item.count_active_file }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-pagination
                      v-if="totaldatabusiness > 0"
                      class="pt-2"
                      v-model="page"
                      :length="pageCount || 0"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination>
                  </div>
                  <h4 class="pt-2 text-center">{{ $t("toolbar.dashboardoutbox.listcompany") }}</h4>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>

        <dialogdashboardoutbox
          :show="opendialogdashboardoutbox"
          :data="datadashboardoutbox"
          @closedialog="opendialogdashboardoutbox = false"
        ></dialogdashboardoutbox>

      </v-main>
    </v-app>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import onebox_toolbar from "../components/layout/layout-toolbar-new";
import VueApexCharts from "vue-apexcharts";
const dialogdashboardoutbox = () => import("../components/optional/dialog-dashboardoutbox");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  name: "dashboardoutbox",
  components: {
    onebox_toolbar,
    apexchart: VueApexCharts,
    dialogdashboardoutbox,
  },
  data: function () {
    return {
      series: [{
        name: "count",
        data: [],
      }],
      loaddataprogress: true,
      loaddataprogress_table: true,
      opendialogdashboardoutbox: false,
      databusiness: [],
      datamonthlyoutbox: [],
      datadashboardoutbox: "",
      total_outbox: 0,
      total_filesize: 0,
      total_outbox_last6months: 0,
      total_filesize_last6months: 0,
      totaldatabusiness: 0,
      search: "",
      timerSearch: null,
      page: 1,
      size: 5,
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "toolbar.dashboardoutbox.companyname",
          align: "left",
          value: "system",
          width: "63%",
          sortable: false,
        },
        {
          text: "toolbar.dashboardoutbox.numberofoutbox",
          align: "center",
          value: "system",
          width: "30%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.totaldatabusiness;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    cardDashborad() {
      return "border-radius: 20px; cursor: default; height: 100%;";
    },
    iconDashboard() {
      return "padding: 3px; border: 3px solid #A8D1E7; border-radius: 50%;";
    },
    iconDashboard_lastMonths() {
      return "padding: 3px; border: 3px solid #F898A4; border-radius: 50%;";
    },
    badgeText() {
      return "color: black !important; margin-top: -4px;"
    },
    headerTable() {
      return ( "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;" );
    },
    headerPage() {
      return ( "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;" );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          background: 'transparant',
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        colors: ['#F898A4', '#FFC29E', '#FCEE9E','#80B7A2', '#A8D1E7', '#B5B1C8'],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#304758"],
            fontWeight: 900
          }
        },
        stroke: {
          show: true,
          width: 2,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'center',
            },
            distributed: true,
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: this.convertMonth(),
          labels: {
            hideOverlappingLabels: false
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
  },
  methods: {
    clickpagination() {
      this.getDataBusiness();
    },
    clearData() {
      this.search = "";
      this.getDataBusiness();
    },
    searchDataBusiness() {
      if (this.timerSearch) {
        clearTimeout(this.timerSearch);
        this.timerSearch = null;
      }
      this.timerSearch = setTimeout(() => {
        this.getDataBusiness();
      }, 800);
    },
    async getDataMonthly(){
      var payload = {
        inbox_type: "outbox",
        data_type: "2",
        data_id: this.dataAccountActive.business_info.business_id,
        search_taxid: "",
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_DASHBOARD_API + "/api/v1/get_dashboard_info", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log('response',response);
          if (response.data.status === "OK") {
            for (let i = 0; i < response.data.data.monthly_usages.length; i++) {
              let data = {};
              data["count_active_file"] = response.data.data.monthly_usages[i]["count_active_file"];
              data["month"] = response.data.data.monthly_usages[i]["month"];
              data["year"] = response.data.data.monthly_usages[i]["year"];
              this.datamonthlyoutbox.push(data);
                          
              this.total_outbox_last6months += response.data.data.monthly_usages[i]["count_active_file"];
              this.total_filesize_last6months += response.data.data.monthly_usages[i]["used_active_storage"];
            }
            this.total_outbox = response.data.data.total_active_file
            this.total_filesize = response.data.data.total_used_active_storage

            this.datamonthlyoutbox = this.datamonthlyoutbox.sort((a, b) => {
              return parseInt(a.year) - parseInt(b.year) || parseInt(a.month) - parseInt(b.month);
            });
            this.series[0].data = this.datamonthlyoutbox.map(item => item.count_active_file);
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });          
    },
    async getDataBusiness(){
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      var payload = {
        sender_type: "2",
        sender_id: this.dataAccountActive.business_info.business_id,
        search: this.search,
        limit: cal_limit,
        offset: cal_offset,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_DASHBOARD_API + "/api/v1/get_receiver_outbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.totaldatabusiness = response.data.total;
            this.databusiness = response.data.data;
            this.loaddataprogress_table = false;
          } else {
            this.loaddataprogress_table = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }          
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress_table = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    convertFileSize(file_size) {
      let result = 0;
      if (file_size >= 1099511627776) {
        result = (file_size / (1099511627776)).toFixed(2) + ' TB';
      } else if (file_size >= 1073741824) {
        result = (file_size / (1073741824)).toFixed(2) + ' GB';
      } else if (file_size >= 1048576) {
        result = (file_size / (1048576)).toFixed(2) + ' MB';
      } else if (file_size >= 1024) {
        result = (file_size / 1024).toFixed(2) + ' KB';
      } else {
        result = file_size.toFixed(2) + ' B';
      }
      return result;
    },
    convertMonth() {
      // console.log('convertMonth', this.datamonthlyoutbox);
      let monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];      
      let convertedData = this.datamonthlyoutbox.map(item => {
        let monthNumber = parseInt(item.month, 10);
        if (monthNumber >= 1 && monthNumber <= 12) {
          return monthAbbreviations[monthNumber - 1] + " " + item.year;
        } else {
          return 'Invalid month';
        }
      });
      return convertedData;
    },
    gotoDialogDashboard(receiver_taxid, item) {
      // console.log("gotoDialogDashboard", receiver_taxid, item);
      this.datadashboardoutbox = item;
      this.opendialogdashboardoutbox = true;
    },
  },
  mounted() {
    this.getDataMonthly();
    this.getDataBusiness();
  },
};
</script>

<style>
#thiscontainer_dashboardoutbox {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>